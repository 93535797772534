"use client";

import Link from "next/link";
import React, { FC, useEffect, useState, useRef, Fragment } from "react";
import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import Image from "next/image";
import axios, { AxiosRequestConfig } from "axios";
import { TypewriterEffectSmooth } from "./typewriter-effect";

interface Props {}

type EventObject = {
  id: number;
  eventId: string;
  bgImage: string;
  profileImg: string;
  signImg: string;
  name: string;
  color: string;
  text1: string;
  text2: string;
  text3: string;
  startDate: Date;
  endDate: Date;
  active: boolean;
  websiteId: string;
  createdAt: string;
  updatedAt: string;
};
const EventPopup: FC<Props> = (): JSX.Element => {
  let [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<EventObject | null>(null);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    fetchEvent();
    async function fetchEvent() {
      const configAxios: AxiosRequestConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.NEXT_PUBLIC_BEARER_TOKEN}`,
        },
      };
      try {
        const response = await axios.get(
          `https://cms.ssdapp.net/api/events?websiteId=BSD`,
          configAxios
        );
        if (
          response.status == 200 &&
          response.data != "No event found in the database."
        ) {
          setData(response.data[0]);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error("Error message:", error.message);
        } else {
          console.error("Unexpected error:", error);
        }
      } finally {
        setIsOpen(true);
      }
    }
  }, []);

  // if (data == null) {
  //   return (
  //     <Dialog
  //       open={isOpen}
  //       onClose={() => false}
  //       transition
  //       aria-label="event popup register"
  //       className="fixed inset-0 flex w-screen items-center justify-center bg-black/45 p-4 transition duration-300 ease-out data-[closed]:opacity-0 z-50"
  //     >
  //       <DialogPanel
  //         as="div"
  //         className="h-fit max-w-4xl w-full rounded-md space-y-4 p-6 lg:p-8 xl:p-14 relative overflow-hidden  bg-[url('/assets/DSC09849-HDR-Pano.webp')] bg-cover bg-center bg-no-repeat bg-black/35 bg-blend-multiply py-14 drop-shadow-md flex items-center justify-center "
  //       >
  //         <button
  //           type="button"
  //           className="size-7 lg:size-10 bg-white text-black rounded-full absolute right-4 top-4 flex justify-center items-center z-20"
  //           onClick={closeModal}
  //         >
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             fill="none"
  //             viewBox="0 0 24 24"
  //             strokeWidth="1.5"
  //             stroke="currentColor"
  //             className="size-5 lg:size-6 shrink-0"
  //           >
  //             <path
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               d="M6 18 18 6M6 6l12 12"
  //             />
  //           </svg>
  //           <span className="sr-only">close</span>
  //         </button>

  //         <div className="!w-full h-fit min-h-[55vh] flex flex-col gap-2 items-center justify-center  ">
  //           <div className="w-24 flex items-center justify-center relative mb-2">
  //             <Image
  //               src="/assets/Bann-logowhite-01.svg"
  //               alt="logo"
  //               sizes="100vw"
  //               priority
  //               width={0}
  //               height={0}
  //               style={{ objectFit: "contain", objectPosition: "center" }}
  //               className="w-full h-full"
  //             />
  //           </div>

  //           <h1 className="text-white font-serif  text-base md:text-lg drop-shadow text-center scale-95 italic leading-snug">
  //             Experience <br /> an urban living phenomenon
  //           </h1>
  //           <p className="text-white text-xl md:text-2xl uppercase font-serif text-center -mt-[2px] max-w-md font-medium drop-shadow ">
  //             Every corner of your new home is a sanctuary
  //           </p>

  //           <span className="w-28 h-[1px] bg-white/75 my-4 md:my-7 opacity-75"></span>

  //           <h1 className="text-white  text-xl font-medium uppercase my-1 drop-shadow-md scale-105 text-center ">
  //             Limited Units Available
  //           </h1>

  //           {/* <p className="text-white/85 text-center text-sm font-normal">
  //             To make an appointment please call
  //             <span className="text-white">
  //               <a href="tel:+6626509899"> (02) 650 9899</a>
  //             </span>
  //           </p> */}

  //           <div className="flex flex-col items-center justify-center gap-7 font-light mt-4 ">
  //             {/* <p className="text-white/85 text-center  text-sm ">
  //               successful registration will entitle to avail of the special
  //               privileges.
  //             </p> */}
  //             <Link href={"/register"}>
  //               <button
  //                 type="button"
  //                 className="border text-white min-w-[140px] hover:scale-110 transition-all duration-200 font-medium text-sm !px-4 !py-2 rounded-full hover:bg-"
  //               >
  //                 REGISTER
  //               </button>
  //             </Link>
  //           </div>
  //         </div>
  //       </DialogPanel>
  //     </Dialog>
  //   );
  // }

  if (data == null) return <></>;

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => false}
        transition
        className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4 transition duration-300 ease-out data-[closed]:opacity-0 z-50"
      >
        <DialogPanel
          as="div"
          className="h-fit w-fit rounded-md space-y-4 p-6 lg:p-8 xl:p-14 relative overflow-hidden "
          style={{
            backgroundImage: `url('${data.bgImage}')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <button
            type="button"
            className="size-7 lg:size-10 bg-white text-black rounded-full absolute right-4 top-4 flex justify-center items-center z-20"
            onClick={closeModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5 lg:size-6 shrink-0"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </button>

          <div className="w-full min-h-[65vh] lg:min-h-[50vh] z-10 grid grid-cols-1 grid-rows-3 lg:grid-rows-1 lg:grid-cols-2  gap-2 xl:gap-4 justify-items-center lg:-translate-y-4">
            <div className="min-h-[300px] w-full h-full flex  relative !row-span-2 lg:row-span-1 -mt-4 lg:mt-0  ">
              <Image
                src={data.profileImg}
                alt="profile"
                sizes="100vw"
                quality={100}
                width={0}
                height={0}
                className="w-full h-full object-contain object-center absolute inset-0 scale-[0.80] lg:scale-[0.9] drop-shadow-md "
              />
              <div className="size-24 lg:size-32 absolute top-0 -left-2  lg:hidden     ">
                <Image
                  src={data.signImg}
                  alt="sign"
                  sizes="100vw"
                  quality={100}
                  width={0}
                  height={0}
                  className="w-full h-full object-contain object-center  "
                />
              </div>
            </div>

            <div
              style={{ color: data.color }}
              className="flex flex-col justify-center items-center gap-2 lg:gap-2 max-w-sm -mt-20 lg:mt-0 lg:-translate-x-5 scale-95 lg:scale-100"
            >
              <div className="size-32 relative hidden lg:block  ">
                <Image
                  src={data.signImg}
                  alt="sign"
                  sizes="100vw"
                  quality={100}
                  width={0}
                  height={0}
                  className="w-full h-full object-contain object-center  "
                />
              </div>
              <p
                className={` text-center !whitespace-pre-line text-sm lg:text-base xl:text-lg drop-shadow-sm`}
              >
                {data.text1.replace(new RegExp("\r?\n", "g"), "<br />")}
              </p>

              <div className="flex flex-col lg:gap-2 ">
                <p
                  className={` text-center !whitespace-pre-line text-3xl lg:text-4xl xl:text-5xl font-semibold drop-shadow-sm`}
                >
                  {data.text2.replace(new RegExp("\r?\n", "g"), "<br />")}
                </p>

                <div className="w-full relative">
                  <Image
                    src={
                      "https://res.cloudinary.com/dndcgytjh/image/upload/fl_preserve_transparency/v1721820504/divide_royal_plj7op.jpg?_s=public-apps"
                    }
                    alt="divide"
                    sizes="100vw"
                    width={0}
                    height={0}
                    className="w-full h-full object-contain object-center drop-shadow-sm"
                  />
                </div>
              </div>
              <p
                className={` text-center whitespace-pre-line text-sm lg:text-base xl:text-lg drop-shadow-sm `}
              >
                {data.text3.replace(new RegExp("\r?\n", "g"), "<br />")}
              </p>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default EventPopup;
